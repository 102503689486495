import "./App.css";
import React, { Suspense } from "react";

const WebApp = React.lazy(() => import("../Web/index"));
const IframeEditor = React.lazy(() => import("../Editor"));
const Debugger = React.lazy(() => import("../Debugger/Debugger"));

export default function App() {
  const isEditor = window.location.pathname === "/iframe";
  const isDebugger = window.location.pathname === "/debugger";

  if (isEditor) {
    return (
      <Suspense fallback={<div></div>}>
        <IframeEditor />
      </Suspense>
    );
  }

  if(isDebugger) {
    return (
      <Suspense fallback={<div></div>}>
        <Debugger />
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<div></div>}>
      <WebApp />
    </Suspense>
  );
}
