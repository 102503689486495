import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './index.css';
import App from './App';
import DebugObserver from './recoil-debugger';
import muiTheme from "./mui-theme";

const theme = createTheme({
  typography: {
    button: {
      textTransform: "capitalize",
      fontWeight: "bold",
    }
  },
  palette: {
    primary: {
      main: '#7b68ee',
    },
    secondary: {
      main: '#f50057',
    },
    success: {
      main: "#11cb5f",
    },
   info: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },

  components: {
    MuiTextField: {

    },
    // Name of the component 
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // color: "#7b68ee",
          // border: "1px solid #7b68ee",
        },
      },
    },
  },
});
const showDebug =
  process.env.NODE_ENV === "development" &&
  localStorage.searchegg_debug === "on";
  
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      {showDebug && <DebugObserver />}
      <RecoilNexus />
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
